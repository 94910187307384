.login-page{
    max-width: 768px;
    position:relative;
    font-size: 0.85rem;

    .terms-and-conductions{
        top: 1px;
        background-color: $gray-100;
        color: $gray-900;
        border-radius: 5rem;
        margin-bottom: 5rem;
    }

    .header{
        margin: 10rem 0rem 7rem 0rem;

        h1{
            font-size: 1.85rem;
            text-align: center;
        }
    }

    h1{
        font-size: 1.35rem;
        text-align: start;
        width: 100%;
        direction: rtl;

    }

    fieldset{
        width: 80%;
        input{
            border-radius: 1rem;
            border: 0rem;
            padding: 0.1rem 1rem;
        }

        @include container(column, flex-end, flex-end);
        div{
            @include container(column, center, center);
            margin-bottom: 1rem;
            width: 100%;
            max-width: 500px;


            h5{
                margin-bottom: 0.5rem;
                text-align: center;
                width: auto;                
            }

            input{
                width: 100%;
                max-width: 16rem;
                text-align: center;
            }
        }

        .btn-pr{
            justify-self: end;
            width: 8rem;
            font-size: 0.85rem;
            height: 1.2rem;
            margin-top: 1rem;
            font-weight: bold;

            &:disabled{
                opacity: 0.5;
            }
        }
    }

    .par{
        text-align: center;
        width: 75%;
        max-width: 270px;  
    }

    .center{
        @include container(column);
        justify-self: center;
        align-self: center;
        padding: 0px;
        width: 100%;

        input{
            width: 80%;
            max-width: 300px;
            text-align: center;
        }

        .btn-pr{
            font-size: 1rem;
        }
    }

    .login-form{
        direction: rtl;
        display: grid;
        grid-template-columns: 6rem auto;
        row-gap: 1rem;

        button{
            margin-left: auto;
        }
    }
}