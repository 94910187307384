.book-page{
    @include container(column, center, center);
    width: 100%;
    margin: auto;
    padding: 0rem;
    max-width: 768px;
    //height: 200vh;

    nav{
        @include container(row, space-between);
        width: 100%;
        padding-right: 1rem;
        background-color: white;
        height: 4rem;
        border-bottom: solid $gray-500 1px;

        .btn-sc{
            border-radius: 0px;
            width: auto;
            column-gap: 0.5rem;

            &:hover{
                background-color: inherit;
            }
        }

        .btn-3{
            @include container(row, center, center);
            direction: rtl;
            width: auto;
            color: $gray-900;
            text-decoration: none;

            label{
                font-size: 1rem;
                margin-bottom: 3.5px;
                cursor: pointer;
                pointer-events: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                -o-user-select: none;
                user-select: none;
            }

            .svg{
                width: 25px;
                height: 100%;
                object-fit: contain;
                transform-origin: all;
                transition-duration: 0.25s;
            }

            .page-dropdown{
                @include container(column, flex-start, flex-start);
                direction: ltr;
                position: absolute;
                z-index: 2;
                top: 3.5rem ;
                background-color: white;
                height: 15rem;
                //padding-right: .25rem;
                overflow-y: auto;
                overflow-x: hidden;
                border: solid $gray-700 1px;
                border-top: 0px;
                box-shadow: -8px 9px 17px 4px rgba(0,0,0,0.68);
                -webkit-box-shadow: -8px 9px 17px 4px rgba(0,0,0,0.68);
                -moz-box-shadow: -8px 9px 17px 4px rgba(0,0,0,0.68);

                @include scrollbar();
                //display: none;
                //transform-origin: opacity;
                //transition-duration: 0.25s;

                span{
                    width: 5.5rem;
                    padding: 0.15rem 0.5rem;
                    font-size: 1rem;
                    border: 0px;
                    background-color: transparent;
                    height: 2rem;
                    &:nth-child(2n){
                        background-color: $gray-400;
                    }
                }
            }
        }

        
    }

    .dropdown-foucs{
        .svg{
            transform: rotateZ(-90deg);
        }

        div{
            display: flex;
            background-color: red;
        }
    }


    #pageContainer{
        //width: 200px;
        //height: 200px;
        overflow: hidden; /* Hide overflowing content */
        svg, img{
            //clip-path: polygon(0 0, 100% 0, 100% 90.5%, 0 90.5%);
            display: block;
            width: 100%;
            pointer-events: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            -o-user-select: none;
            user-select: none;
            pointer-events: none;
            
        } 


        .page-placeholder{
            @include container();
            width: 100vw;
            height: calc(100vw * 1.588);
            max-height: calc(768px * 1.588);
            background-color: rgb(255, 255, 255);
            max-width: 768px ;
            box-shadow: 0px 0px 35px 8px rgba(0,0,0,0.73) inset;
            //filter: blur(5px);

            .loader{
                border: 16px solid lightgray; /* Light grey */
                border-top: 16px solid black; /* Blue */
                border-radius: 50%;
                width: 8rem;
                aspect-ratio: 1;
                animation: spin 1.5s linear infinite;
                backdrop-filter: blur(5px);
            }
        }
    }


    footer{
        height: 10px;
        width: 100%;

        #nextButtons{
            @include container(row, space-between);
            position: relative;
            width: 100%;
            height: calc(100vw * 1.588 * 0.1);
            max-height: calc(768px * 1.588 * 0.1);
            bottom: calc(100vw * 1.588 * 0.1);
            background-color: transparent;
            //border-top: solid $gray-500 1px;
            max-width: 768px;
            overflow-y: hidden;
            
            .white{
                filter: invert(100%);
            }
            
            .btn-left, .btn-right{
                opacity: 0.5;
                //background-color: $success-color;
                width: 5rem;
                height: 4rem;
                border: 0px;
                margin: 0rem 0.5rem;
                transform: rotateZ(180deg);
                color: transparent;
                //filter: invert(100%);
   
                &:hover{
                    opacity: 1;
                }
            }    
    
            .btn-left{
                //background-color: $error-color;
                transform: rotateZ(0deg);
         }
    
            
        }
    }

    #examForm{
        width: 100%;
        backdrop-filter: blur(3px);
        fieldset{
            @include container(column, flex-start, flex-start);
            direction: rtl;
            margin-bottom: 1rem;
            padding: 1rem;  
            p{
                margin-bottom: 1rem;
            }
            span{
                margin-bottom: 0.5rem;
                line-height: 1.5rem;
                margin-right: 1rem;
            }
        }

        .btns{
            @include container(row, space-around);
            width: 90%;
            column-gap: 2rem;
            margin: 0rem auto;
        }
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

@media print { body, div, p { display: none } }