.user-profile-data{
    @include container(column);
    width: 100%;

    h2{
        color: white;
        margin-bottom: 0.5rem;
        font-size: 0.9rem;
    }

    input{
        width: 80%;
        margin-bottom: 2rem;
        height: 1.5rem;
        padding: 0.5rem;

        border-radius: 1rem;
        border: 0px;
        text-align: center;
    }

    .btn-pr{
        align-self: center;
        width: auto;
        font-size: 0.85rem;
    }
}