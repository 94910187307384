.extra-content{
    direction: rtl;
    align-items: flex-start;
    max-width: 768px;
    p{
        line-height: 1.35rem;
    }


    .loader {
        border: 0.2rem solid #f3f3f3; /* Light grey */
        border-top: 0.2rem solid #3498db; /* Blue */
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        animation: spin 1.5s linear infinite;
      }
      
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }

    .btn-pr{
        align-self: center;
        width: auto;
        font-size: 0.85rem;
    }

    .used{
      color: rgb(255, 0, 255);
    }
}