//white breaks
.break-05{
    margin-bottom: 0.5rem;
}

.break-1{
    margin-bottom: 1rem;
}

.break-2{
    margin-bottom: 2rem;
}

.break-4{
    margin-bottom: 4rem;
}

.break-8{
    margin-bottom: 8rem;
}
