.pr-btns{
    @include container(column);
    width: 100%;

    button,a{
        height: 4rem;
        text-decoration: none;
    }
}

.sc-btns{
    @include container(column);
    width: 100%;

    button{
        height: 2rem;
        padding: 1.125rem 1rem;
    }
}

.spaced-content{
    justify-content: space-between;
}

.center{
    width: 80%;
    text-align: center;
}