//inital desgin for small 320<480 

//big mobile 480x<768  576px
@media only screen and (min-width: 685px) {
    .login-page{
        fieldset{
            div{
                width: 100%;
                flex-direction: row-reverse;
                justify-content: end;
                align-items: center;

                h5{
                    text-align: end;
                    width: 8rem;
                }

                input{
                    max-width: 13rem;
                }
           } 
        }

        #personalInfo, #activationCodeInfo{
            width: 75vw;
            align-self: flex-end;
            justify-self: flex-end;
            margin-right: 2rem;
        }

        .par{
            font-size: 1.15rem;
            *{font-size: 1.15rem;}
        }
    }

    .book-page{
        footer{
            #nextButtons{
                bottom: calc(768px * 1.588 * 0.1);
            }
        }
    }

    .intro-page{
        .top-section{
            padding: 0rem 2rem;
            .header-moto{
                text-align: end;
                align-items: end;
               
                h1{
                    font-size: 3rem;
                    line-height: 3.5rem;
                    width: 20rem;
                }

                h5{
                    width: 100%;
                }
            }
        }

        .middle-section{
            background: url(../../assets/images/background-cut-2.2.png) no-repeat center center; 
            
            .floating-point{
                display: inline;
            }

            .middle-section-points{
                align-items: flex-end;
                padding: 0rem 2rem;
            }

            
        }
        
        .white-space-2{
            height: 150px;
        }

        .footer-section{
            flex-direction: row;
            direction: rtl;
        }
        
    }

}



/*
//tablet 768<992 
@media only screen and (min-width: 768px) {


}
//desktop 992<x<1200 
@media only screen and (min-width: 992px) {
    #root{
        //max-width: 1400px;
    }
}

//TV x>1200 
@media only screen and (min-width: 1200px) {
    #root{
        max-width: 1200px;
    }
}
*/