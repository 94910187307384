.intro-page{
    width: 100vw;
    max-width: 786px;

    a{
        text-decoration: none;
    }

    .btn-sc, a{
        width: auto;
        font-size: 0.75rem;
        color: white;
        background-color: transparent;
        border: solid white 0.1rem;
        border-radius: 0.5rem;

        &:hover{
            background-color: lightblue;
        }
    }

    .top-section{   
        width: 100%;
        height: 422px;    
        background: url(../../assets/images/background-cut-1.png) no-repeat center center; 
        padding: 0rem 1rem;

        nav{
            @include container(row, space-between);
            height: 3rem;    
            width: 100%;
            padding: 2.5rem 0rem;
            
            span{
                @include container(row);
                width: auto;
            }

            .nav-btns{
                column-gap: 0.5rem;
                height: 2.5rem;

                a{
                    padding: 0.55rem 0.8rem;
                }
            }

            img{
                width: 2rem;
                aspect-ratio: 1;
                margin-right: 0.25rem;
            }
        }

        .header-moto{
            @include container(column, space-between);
            row-gap: 2rem;
            padding-top: 5rem;
            padding-bottom: 1.5rem;
            width: 100%;
            height: calc(100% - 5rem);
            text-align: center;

            h1{
                font-weight: bold;
                width: 15rem;
                word-spacing: 0.5rem;
                line-height: 2.5rem;
            }


            h5{
                width: 80%;
                line-height: 1.25rem;
            }
        }
    }
    
    .white-space, .white-space-2{
        @include container(column);
        width: 100%;
        height: 122px;
        color: $gray-900;
        row-gap: 0.5rem;
        padding: 0rem 1rem;
        text-align: center;

        h3{
            font-weight: bold;
        }

        p{
            margin: 0rem 10%;
            line-height: 1.25rem;
        }
    }

    .white-space-2{
        height: auto;
        margin: 1rem 0rem;
    }

    .middle-section{
        @include container(row, space-between);
        width: 100%;
        height: 225px;    
        background: url(../../assets/images/background-cut-2.1.png) no-repeat center center;  
        padding: 0rem 1rem;
        
        .floating-point{
            display: none;
            position: absolute;
            top: calc(422px + 122px + 2rem)
        }

        .middle-section-points{
            @include container(column, center, center);
            width: 100%;
            text-align: center;
            row-gap: 0.5rem;

            h4{
                line-height: 1.25rem;
                border: solid white 0.15rem;
                border-radius: 0.5rem;
                padding: 0.5rem 0.5rem;
            }
        }

    }

    .footer-section{
        @include container(column);
        row-gap: 2.5rem;
        column-gap: 7.5rem;
        width: 100%;
        height: 269px;    
        background: url(../../assets/images/background-cut-3.png) no-repeat center center; 

        label{
            font-size: 2.5rem;
            line-height: 3rem;
            font-weight: bold;
            width: 20rem;
            text-align: center;
        }

        a{
            font-size: 1.75rem;
            width: 10rem;
            border-width: 0.2rem;
        }
    }

}


