.terms-and-conductions{
    @include container(column, flex-end, flex-end);
    text-align: end;
    color: $text-color;
    font-size: 0.85rem;

    h1{
        text-align: end;
        color: inherit;
    }

    h5{
        font-size: 1rem;
        width: 100%;
        padding-bottom: 0.5rem;
        font-weight: 600;
    }

    p{
        padding-bottom: 2rem;
        line-height: 1.15rem;
        margin-right: 1em;
        max-width: 60ch;
    }
    
    .blue-text{
        color: rgb(0, 146, 146);
    }

    .center-text{
        align-self: center;
        text-align: center;
        margin: 0rem;
        width: 80%;
        font-weight: bold;
    }

    .btn-pr{
        align-self: center;
        width: auto;
        font-size: 0.85rem;
        
    }

    //remove the last letter that keeps english and arabic mixed
    em{
        color: transparent;
    }
}