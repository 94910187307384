.footer{
    @include container(column);
    row-gap: 0.5rem;
    width: 100%;

    p{
        font-size: 1rem;
        color: white;
    }

    button{
        color: cyan;
    }
}