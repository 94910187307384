@mixin container($dir:colume, $justify:center, $horz:center){
    display: flex;
    align-items: $horz;
    justify-content: $justify;
    flex-direction: $dir;
}

@mixin font($font-family, $font-file) {
    @font-face {
      font-family: $font-family;
      src: url($font-file+$font-family+'.eot');
      src: url($font-file+$font-family+'.eot?#iefix') format('embedded-opentype'),
           url($font-file+$font-family+'.woff') format('woff'),
           url($font-file+$font-family+'.ttf') format('truetype'),
           //url($font-file+$font-family+'.svg#'+$font-family) format('svg')
           ;
      font-weight: normal;
      font-style: normal;
    }
  }
  // src\assets\Fonts\Almarai\Almarai-Bold.eot

@mixin scrollbar($width:10px){ 
  &::-webkit-scrollbar {
    width: $width;
}
 
&::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $gray-900; 
    border-radius: 8px;
    -webkit-border-radius: 8px;
}
 
&::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $gray-500; 
    -webkit-box-shadow: inset 0 0 6px $gray-900;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    -webkit-border-radius: 10px;
}
}
  