/*
Set the box-sizing property to border-box for all elements, 
making it easier to manage element sizing and layout. 
The default value of box-sizing is content-box, meaning padding, borders, and margins 
are not included in the dimensions
*/

*,
*::before,
*::after {
  box-sizing: border-box;
}

//base fonts & colors
//@import url('https://fonts.googleapis.com/css2?family=Almarai&display=swap');
@include font('Almarai-Regular', '../../assets/Fonts/Almarai/');
@include font('Almarai-Bold', '../../assets/Fonts/Almarai/');

html{
  scroll-behavior: smooth;
  height: 100%;
}


body{
    @include container(column, flex-start, center);
    height: 100%;
    font-family: 'Almarai-Regular', 'Almarai-Bold';
    color: white;

    @include scrollbar(12px);
}

.root-center{
  @include container(column);
  width: 100%;
  height: 100%;
  padding: 3rem;
}

.root-basic{
  @include container(column);
  padding: 3rem;
  //position: fixed;
  //width: 100%;
  //height: 100%;
}

.colored{
  color: cyan;
}

*{
  font-family: 'Almarai-Regular', 'Almarai-Bold';
}


h1{
  font-size: 2rem;
}

h2{
  font-size: 1.85rem;
}

h3{
  font-size: 1.35rem;
}

h4{
  font-size: 1rem;
}

h5{
  font-size: 0.85rem;
}