.btn-sc, .btn-pr{
    @include container(row);
    font-size: 1.25rem;
    font-weight: bold;
    border: 0px;
    background-color: cyan;
    color: $gray-900;
    width: 100%;
    border-radius: 1rem;
    padding: 0.35rem 0.8rem;

    transition: background-color;
    transition-duration: 0.5s;
    cursor: pointer;


    img{
        width: 50px;
        height: 100%;
        object-fit: contain;

    }

    &:hover{
        background-color: darkcyan;
    }
}

.btn-sc{
    background-color: white;

    &:hover{
        background-color: $gray-500;
    }
}

.btn-3{
    background-color: transparent;
    border: 0px;
    color: cyan;
    text-decoration: underline;
    //border-bottom: solid 1px;
    font-size: 0.85rem;

    &:hover{
        cursor: pointer;
    }
}