.FAQ-page{
    width: 100vw;
    max-width: 768px;

    h1{
        width: 100%;
        font-weight: bold;
        text-align: start;

    }
    form{
        width: 100%;
        color: $gray-900;
        @include container(column);
        background-color: $gray-100;
        padding: 1rem;
        row-gap: 0.5rem;
        border-radius: 1rem;


        label{
            align-self: flex-end;
        }

        textarea{
            justify-content: start;
            text-align: start;
            height: 10rem;
            width: 100%;
            resize: none;
        }

        h1{
            text-align: center;
        }

        div{
            width: 100%;
            @include container(row, space-between);
            padding: 0rem 0rem;

            button{
                width: auto;
                font-size: 1rem;
                border: solid 0.1rem $gray-900;

                &:disabled{
                    opacity: 0.5;
                }
            }
        }
    

    }

    
}