.header-1{
    width: 100%;
    color: white;
    font-size: 1.35rem;
    font-weight: bold;
    //letter-spacing: 0.1rem;
    text-align: center;
}

.header-r{
    text-align: right;
}